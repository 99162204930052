import React from 'react';
import IframeResizer from 'iframe-resizer-react';

const Iframe = ({ data: { url, height, width }, ...props }) => {
  const resizable = true;

  return resizable ? (
    <IframeResizer
      log
      id="keela_nmHJ4u49veSzaLSo9"
      src={url}
      frameBorder="0"
      heightCalculationMethod="max"
      minHeight="500"
      scrolling={true}
      style={{
        width: '1px',
        minWidth: '100%',
      }}
    />
  ) : (
    <iframe
      title="Careers"
      id="keela_nmHJ4u49veSzaLSo9"
      width={width || '100%'}
      height={height || '500px'}
      scrolling="yes"
      frameBorder="0"
      src={url}
    />
  );
};

export default Iframe;
